@media (max-width: 991.98px) {
  .navbar-btn {
      width:100%;
      padding: .8125rem 1.25rem;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      font-size: 1.0625rem
  }

  .navbar-collapse.collapsing {
      opacity: 0;
      transform: scale(.9)
  }
}

.navbar-collapse .navbar-toggler {
  position: absolute;
  top: 1rem;
  right: 1rem;
  z-index: 1
}

@media (min-width: 992px) {
  .navbar-collapse .navbar-toggler {
      display:none
  }
}

@media (max-width: 991.98px) {
  .navbar-collapse .navbar-nav .nav-item {
      padding:1.5rem
  }

  .navbar-collapse .navbar-nav .nav-item+.nav-item {
      border-top: 1px solid #f1f4f8;
  }
}

@media (max-width: 991.98px) {
  .navbar-collapse .navbar-nav .nav-link {
      padding-top:0;
      padding-bottom: 0
  }
}