// Import all of Bootstrap's CSS
//@import "~bootstrap/scss/bootstrap";

// Configuration
/*@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
*/
// Layout & components
/*
@import "~bootstrap/scss/nav";
*/
//@import "~bootstrap/scss/navbar";
/*
@import "~bootstrap/scss/dropdown";
*/

// Helpers
//@import "~bootstrap/scss/helpers";
/*@font-face {
    font-family: myFirstFont;
    src: url(sansation_light.woff);
  }
  
  body {
    font-family: myFirstFont;
  }
*/

@import "./css/styles.css";
@import '~aos/dist/aos.css';
@import "./scss/nav.scss";
@import "./css/custom.css";
@import "~jarallax/dist/jarallax.css";

