
.shape {
    position: absolute;
    pointer-events: none
}

.shape>* {
    display: block
}

.shape:not([class*=shape-blur]) {
    overflow: hidden
}

.shape:not([class*=shape-blur])>* {
    transform: scale(2)
}

@media (-ms-high-contrast:active),(-ms-high-contrast:none) {
    .shape>svg {
        display: none
    }
}


.shape-end {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0
}

.shape-end>* {
    position: inherit;
    top: inherit;
    bottom: inherit;
    right: inherit;
    transform-origin: center left
}


.shape-bottom {
    right: 0;
    bottom: 0;
    left: 0
}

.shape-bottom>* {
    transform-origin: top center
}

.shape-fluid-x>* {
    width: 100%;
    height: auto
}

.shape-fluid-y>* {
    width: auto;
    height: 100%
}

.text-gray-600 {
    color: #869ab8!important
}
.text-gray-700 {
    color: #506690!important
}
.bg-gray-300 {
    background-color: #d9e2ef!important
}
.bg-gray-900 {
    background-color: #1b2a4e!important
}
.h6.text-uppercase,h6.text-uppercase {
    letter-spacing: .08em
}
.display-1,.display-2,.display-3,.display-4 {
    letter-spacing: -.02em
}

.overlay {
    position: relative
}

.overlay:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: inherit
}

.overlay-60:before {
    opacity: .4
}
.overlay-black:before {
    background-color: #161c2d
}

.card-zoom {
    overflow: hidden!important;
    border-radius: inherit;
}

.card-zoom>[class*=card-img] {
    transition: all .3s ease;
    transform-origin: center center
}

.card:hover>.card-zoom>[class*=card-img] {
    transform: scale(1.1)
}

.py-8 {
    padding-top: 3rem!important;
    padding-bottom: 3rem!important
}
.py-10 {
    padding-top: 5rem!important;
    padding-bottom: 5rem!important
}
.py-15 {
    padding-top: 16rem!important;
    padding-bottom: 16rem!important
}
.mt-n10 {
    margin-top: -5rem!important
}
.pb-12 {
    padding-bottom: 8rem!important
}
.pt-7 {
    padding-top: 2.5rem!important
}

.pt-12 {
    padding-top: 8rem!important
}

.mb-7 {
    margin-bottom: 2.5rem!important
}

.max-height-section img {
    width: 100%;
}

@media (min-width: 576px) {

}
@media (min-width: 768px) {
    .py-md-11 {
        padding-top: 6rem!important;
        padding-bottom: 6rem!important;
    }
    .py-md-14 {
        padding-top: 12rem!important;
        padding-bottom: 12rem!important
    }
    .mt-md-n14 {
        margin-top: -12rem!important;
    }
    .pt-md-14 {
        padding-top: 12rem!important;
    }
    .pt-md-10 {
        padding-top: 5rem!important
    }
    
    .pb-md-15 {
        padding-bottom: 16rem!important;
    }
    .py-md-10 {
        padding-top: 5rem!important;
        padding-bottom: 5rem!important
    }
    .vh-md-100 {
        height: 100vh!important
    }
}

@media (min-width: 1024px)  {
    .max-height-section img {
        max-height: 100vh;
        object-fit: contain;  /* Pour conserver les proportions de l'image */
    }
}

.bg-cover {
    background: no-repeat 50%/cover;
}

.lazyload {
    opacity: 0; /* Image invisible tant qu'elle n'est pas chargée */
    transition: opacity 0.4s ease-in-out;
}

.lazyloaded {
    opacity: 1; /* Image devient visible après chargement */
}

.fs-lg {
    font-size: 1.1875rem!important
}
.hr-sm {
    width: 60px
}


